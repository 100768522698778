import React, { useState, Children, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
// import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { BigNumber, ethers } from 'ethers';
import {
  onClickJump,
  getTotalRemaining,
  getCurrentPrice,
  getNextPrice,
  getPriceIndex,
  getMulPrice,
  getChainIndex,
} from '../../utils/common';
import chainData from '../../utils/chainData.json';
import contractAddress from '../../utils/contractAddress.json';
import proxyAddress from '../../utils/proxyAddress.json';
import usdcData from '../../utils/usdc.json';
import erc20Abi from '../../assets/contract/erc20.json';
import BoxerContract from '../../assets/contract/Boxer.json';
import ProxyContract from '../../assets/contract/BoxerProxy.json';
import TokenItem from '../../components/TokenItem';
import topImage from '../../assets/top_background.png';
import bottomImage from '../../assets/bottom_background.png';
import titleImage from '../../assets/title.png';
import subtitleImage from '../../assets/subtitle.png';
import box1Image from '../../assets/box1.png';
import box2Image from '../../assets/box2.png';
import box3Image from '../../assets/box3.png';
import box4Image from '../../assets/box4.png';
import box5Image from '../../assets/box5.png';
import logo1 from '../../assets/network/ethereum.png';
import logo2 from '../../assets/network/binance.png';
import logo3 from '../../assets/network/avalanche.png';
import logo4 from '../../assets/network/polygon.png';
import logo5 from '../../assets/network/arbitrum.png';
import logo6 from '../../assets/network/optimism.png';
import logo7 from '../../assets/network/fantom.png';
import step1Img from '../../assets/priceSchedule/step1.png';
import step2Img from '../../assets/priceSchedule/step2.png';
import step3Img from '../../assets/priceSchedule/step3.png';
import step4Img from '../../assets/priceSchedule/step4.png';
import step5Img from '../../assets/priceSchedule/step5.png';
import step6Img from '../../assets/priceSchedule/step6.png';
import step7Img from '../../assets/priceSchedule/step7.png';
import usdcImg from '../../assets/usdc.png';
import './Home.css';

const { abi } = BoxerContract;
const proxyAbi = ProxyContract.abi;
const usdclist = usdcData.mainnet;
const chainlist = chainData.mainnet;
const addresslist = contractAddress.mainnet;
const proxylist = proxyAddress.mainnet;
// const usdclist = usdcData.testnet;
// const chainlist = chainData.testnet;
// const addresslist = contractAddress.testnet;
// const proxylist = proxyAddress.testnet;
const logolist = [logo1, logo2, logo3, logo4, logo5, logo6, logo7];
const steplist = [
  step1Img,
  step2Img,
  step3Img,
  step4Img,
  step5Img,
  step6Img,
  step7Img,
];

function requestAccount(i) {
  window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [chainlist[i]],
  });
}

// function getCurrent(addr, signer) {
//   const boxerProxy = new ethers.Contract(addr, proxyAbi, signer);
//   boxerProxy
//     .current()
//     .then(() => {})
//     .catch((err) => {
//       console.log(err.error.message);
//     });
// }

async function switchChain(i) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainlist[i].chainId }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [chainlist[i]],
        });
      } catch (addError) {
        // handle "add" error
      }
    }
  }
}

async function checkAccountChange(onConnect) {
  const { ethereum } = window;
  if (ethereum) {
    ethereum.on('accountsChanged', (accounts) => {
      if (accounts === undefined || accounts === null || accounts.length <= 0) {
        // disconnect
        // onDisconnect();
      } else {
        // connect new
        onConnect(accounts[0]);
      }
    });
  }
}

// async function connect(onConnect, onDisconnect) {
//   if (!window.ethereum) {
//     return;
//   }
//   const accounts = await window.ethereum.request({
//     method: 'eth_requestAccounts',
//   });
//   onConnect(accounts[0]);
//   switchChain();
//   checkAccountChange(onConnect, onDisconnect);
// }

async function checkIfWalletIsConnected(onConnect, onDisconnect) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: 'eth_accounts',
    });
    if (accounts.length > 0) {
      const account = accounts[0];
      onConnect(account);
      checkAccountChange(onConnect, onDisconnect);
    }
  }
}

function Home() {
  const [open, setOpen] = React.useState(false);
  const [chainIndex, setChainIndex] = useState(0);
  const [tokenList, setTokenList] = useState([]);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [currentPrice, setCurrentPrice] = useState(70);
  const [current, setCurrent] = useState(0);
  const [nextPrice, setNextPrice] = useState(120);
  const [totalRemainng, setTotalRemainng] = useState(1400);
  const [priceIndex, setPriceIndex] = useState(0);
  const [value, setValue] = useState(1);
  const [buyButton, setBuyButon] = useState(false);
  const [errorMsg, setErrorMsg] = useState('execution reverted');
  const [approveLoading, setAproveLoading] = React.useState(false);
  // const [mintLoading, setMintLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchPrice = async (index) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const proxy = new ethers.Contract(proxylist[index], proxyAbi, provider);
    proxy.current().then((res) => {
      const cur = res.toNumber();
      setCurrent(cur);
      setPriceIndex(getPriceIndex(cur));
      setCurrentPrice(getCurrentPrice(cur));
      setNextPrice(getNextPrice(cur));
      setTotalRemainng(getTotalRemaining(cur));
    });
  };

  const handleChainIndexChange = async (event) => {
    await switchChain(event.target.value * 1);
    setChainIndex(event.target.value * 1);
    fetchPrice(event.target.value * 1);
  };

  // async function switchChain(i) {
  //   try {
  //     await window.ethereum.request({
  //       method: 'wallet_switchEthereumChain',
  //       params: [{ chainId: chainlist[i].chainId }],
  //     });
  //   } catch (switchError) {
  //     // This error code indicates that the chain has not been added to MetaMask.
  //     if (switchError.code === 4902) {
  //       try {
  //         await window.ethereum.request({
  //           method: 'wallet_addEthereumChain',
  //           params: [chainlist[i]],
  //         });
  //       } catch (addError) {
  //         // handle "add" error
  //       }
  //     }
  //   }
  // }

  const checkChainIndex = () => {
    if (window.ethereum) {
      const id = window.ethereum.networkVersion;
      if (id === '250') {
        setChainIndex(6);
      } else if (id === '1') {
        setChainIndex(0);
      } else if (id === '10') {
        setChainIndex(5);
      } else if (id === '42161') {
        setChainIndex(4);
      } else if (id === '137') {
        setChainIndex(3);
      } else if (id === '43114') {
        setChainIndex(2);
      } else if (id === '56') {
        setChainIndex(1);
      }
    }
  };

  const onConnect = (account) => {
    checkChainIndex();
    setUserAddress(account);
    setConnected(true);
    fetchPrice(getChainIndex(window.ethereum.networkVersion));
  };
  // const onChainChange = () => {
  //   window.ethereum.on('chainChanged', checkChainIndex);
  // };

  useEffect(() => {
    checkChainIndex();
    checkIfWalletIsConnected(onConnect);
    // onChainChange();
  }, []);

  const handleConnect = async () => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    if (accounts.length > 0) {
      checkChainIndex();
      setConnected(true);
      setUserAddress(accounts[0]);
      requestAccount(chainIndex);
      switchChain(chainIndex);
      fetchPrice(chainIndex);
    }
  };

  const fetchTokens = async () => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    requestAccount(chainIndex);
    switchChain(chainIndex);
    if (accounts.length > 0) {
      onConnect(accounts[0]);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const boxer = new ethers.Contract(addresslist[chainIndex], abi, provider);
      const r1 = await boxer.balanceOf(accounts[0]);
      const num = r1.toNumber();
      const tlist = [];
      setTokenLoading(true);
      for (let index = 0; index < num; index += 1) {
        /* eslint-disable no-await-in-loop */
        const r2 = await boxer.tokenOfOwnerByIndex(accounts[0], index);
        const t = { tokenId: r2.toString(), currentIndex: chainIndex };
        tlist.push(t);
      }
      setTokenList(tlist);
      setTokenLoading(false);
    }
  };

  const onClickCross = async (chainId, tokenId, messageFee) => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    requestAccount(chainIndex);
    if (accounts.length > 0) {
      onConnect(accounts[0]);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const boxer = new ethers.Contract(addresslist[chainIndex], abi, signer);
      console.log('chainId: ', chainId);
      boxer
        .crossChain(chainId, BigNumber.from(tokenId), {
          value: ethers.utils.parseEther(`${messageFee}`),
        })
        .then(() => {
          fetchTokens();
        })
        .catch(() => {});
    }
  };

  const handleValueChange = (v) => {
    setValue(v.target.value * 1);
  };

  function buy(_value, addr, signer) {
    const boxerProxy = new ethers.Contract(addr, proxyAbi, signer);
    boxerProxy
      .buy(_value)
      .then(() => {
        // setLoading(false);
      })
      .catch((err) => {
        if (err.data.message) {
          setErrorMsg(err.data.message.replace('execution reverted: ', ''));
          setOpen(true);
        }
      });
  }

  const waitApprove = async (_usdc, _addr) => {
    setAproveLoading(true);
    const tx = await _usdc.approve(_addr, ethers.constants.MaxInt256);
    await tx.wait();
    setAproveLoading(false);
    setBuyButon(true);
  };

  function mint(_current, _value, usdcItem, addr, signer, _userAddress) {
    if (value <= 0) {
      return;
    }
    // setLoading(true);
    const price = getMulPrice(current, value);
    const amount = ethers.utils.parseUnits(`${price}`, usdcItem.decimal);
    const usdc = new ethers.Contract(usdcItem.address, erc20Abi, signer);
    usdc.allowance(_userAddress, addr).then((res) => {
      if (res.gte(amount)) {
        // buy
        buy(value, addr, signer);
      } else {
        try {
          waitApprove(usdc, addr);
        } catch (err) {
          if (err.data.message) {
            setErrorMsg(err.data.message.replace('execution reverted: ', ''));
            setOpen(true);
          }
        }
        // setBuyButon(false);
        // usdc
        //   .approve(addr, ethers.constants.MaxInt256)
        //   .then((r1) => {
        //     if (r1.hash) {
        //       // console.log(r1.hash)
        //       // const isMined = await receipt(r1.hash, signer);
        //       // const isMined = await receipt(res.hash, provider);
        //       // console.log(isMined);
        //       // usdc.once('Approval', (owner, spender) => {
        //       //   if (
        //       //     owner.toUpperCase() === _userAddress.toUpperCase() &&
        //       //     spender.toUpperCase() === addr.toUpperCase()
        //       //   ) {
        //       //     // setLoading(false);
        //       //     setBuyButon(true);
        //       //   }
        //       // });
        //     }
        //   })
        //   .catch(() => {
        //     // setLoading(false);
        //   });
      }
    });
  }

  function approve(_current, _value, usdcItem, addr, signer, _userAddress) {
    if (value <= 0) {
      return;
    }
    // setLoading(true);
    const price = getMulPrice(current, value);
    const amount = ethers.utils.parseUnits(`${price}`, usdcItem.decimal);
    const usdc = new ethers.Contract(usdcItem.address, erc20Abi, signer);

    usdc.allowance(_userAddress, addr).then((res) => {
      if (res.gte(amount)) {
        // buy
        setBuyButon(true);
      } else {
        // console.log(res);
        // usdc
        //   .approve(addr, ethers.constants.MaxInt256)
        //   .then((r1) => {
        //     if (r1.hash) {
        //       // usdc.once('Approval', (owner, spender) => {
        //       //   if (
        //       //     owner.toUpperCase() === _userAddress.toUpperCase() &&
        //       //     spender.toUpperCase() === addr.toUpperCase()
        //       //   ) {
        //       //     setAproveLoading(false);
        //       //     setBuyButon(true);
        //       //   }
        //       // });
        //       console.log(r1.hash);
        //       receipt(r1.hash, signer).then((r2) => {
        //         console.log(r2);
        //       });
        //       setAproveLoading(false);
        //     }
        //   })
        //   .catch((err) => {
        //     if (err.data.message) {
        //       setErrorMsg(err.data.message.replace('execution reverted: ', ''));
        //       setOpen(true);
        //     }
        //   });
        try {
          waitApprove(usdc, addr);
        } catch (err) {
          if (err.data.message) {
            setErrorMsg(err.data.message.replace('execution reverted: ', ''));
            setOpen(true);
          }
        }
      }
    });
  }

  const onClickMint = async () => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    requestAccount(chainIndex);
    switchChain(chainIndex);
    if (accounts.length > 0) {
      onConnect(accounts[0]);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      mint(
        current,
        value,
        usdclist[chainIndex],
        proxylist[chainIndex],
        signer,
        userAddress
      );
    }
  };

  const onClickApprove = async () => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    requestAccount(chainIndex);
    switchChain(chainIndex);
    if (accounts.length > 0) {
      onConnect(accounts[0]);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      approve(
        current,
        value,
        usdclist[chainIndex],
        proxylist[chainIndex],
        signer,
        userAddress
      );
    }
  };

  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  return (
    <div className="App">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{
          // width: '5.5rem',
          fontSize: '0.2rem',
        }}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: '100%', fontSize: '0.2rem' }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <div className="top-background">
        <img alt="" src={topImage} className="top-image" />
        <div className="header-container">
          <div className="header-wrapper">
            <div className="left-header">
              <div className="header-title-wrapper">
                <img alt="" src={titleImage} className="title-image" />
                <img alt="" src={subtitleImage} className="subtitle-image" />
              </div>
            </div>
            <div className="right-header">
              {connected ? (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    width: '2rem',
                    height: '0.5rem',
                    zIndex: 2,
                    background: 'linear-gradient(90deg, #2B2451, #4B3E7A)',
                    boxShadow: '15px 15px 20px -4px #41356F',
                    borderRadius: '24px',
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                      color: '#fff',
                    }}
                  >
                    {userAddress.substring(0, 5)}…
                    {userAddress.substring(userAddress.length - 4)}
                  </Button>
                </Box>
              ) : (
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    width: '2rem',
                    height: '0.5rem',
                    zIndex: 2,
                    background: 'linear-gradient(90deg, #2B2451, #4B3E7A)',
                    boxShadow: '15px 15px 20px -4px #41356F',
                    borderRadius: '24px',
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                      color: '#fff',
                    }}
                    onClick={() => handleConnect()}
                  >
                    Connect wallet
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="box-container">
        <div className="box-wrapper">
          <img alt="" src={box1Image} className="box-image-item" />
          <img alt="" src={box2Image} className="box-image-item" />
          <img alt="" src={box3Image} className="box-image-item" />
          <img alt="" src={box4Image} className="box-image-item" />
          <img alt="" src={box5Image} className="box-image-item" />
        </div>
      </div>
      <div className="main-wrapper">
        <Box
          style={{
            width: '100%',
            // height: '400px',
            borderRadius: '10px',
            boxShadow: '15px 15px 20px -4px #41356F',
            backgroundImage:
              'linear-gradient(90deg, rgba(39,30,77, 0.8), rgba(63,62,128, 0.8))',
          }}
        >
          <div
            style={{
              display: 'flex',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              boxSizing: 'border-box',
              paddingLeft: '0.7rem',
              paddingRight: '0.7rem',
            }}
          >
            <Box
              style={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  height="0.45rem"
                  width="100%"
                >
                  <Box
                    display="flex"
                    sx={{
                      background: '#3C4BC2',
                      borderRadius: '4px',
                    }}
                  >
                    <Select
                      sx={{
                        width: '1.8rem',
                        height: '0.45rem',
                        background: '#3C4BC2',
                        color: '#fff',
                      }}
                      value={chainIndex}
                      onChange={handleChainIndexChange}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {Children.toArray(
                        chainlist.map((item, index) => (
                          <MenuItem value={index}>
                            <div
                              style={{
                                // background: '#3C4BC2',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '0.18rem',
                                // color: '#fff',
                              }}
                            >
                              <Avatar
                                sx={{ width: '0.3rem', height: '0.3rem' }}
                                src={logolist[index]}
                                alt=""
                              />
                              <span style={{ marginLeft: '0.1rem' }}>
                                {item.chainName}
                              </span>
                            </div>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    <Box display="flex" alignItems="center" ml={1} mr={2}>
                      <Avatar
                        src={usdcImg}
                        sx={{
                          width: '0.3rem',
                          height: '0.3rem',
                        }}
                      />
                      <span style={{ fontSize: '0.18rem', color: '#fff' }}>
                        USDC
                      </span>
                    </Box>
                  </Box>
                  <Box display="flex" height="0.45rem">
                    <input
                      type="number"
                      onChange={handleValueChange}
                      defaultValue={1}
                      style={{
                        width: '0.7rem',
                        textAlign: 'center',
                        backgroundColor: 'transparent',
                        color: '#FFF',
                        borderColor: '#FFF',
                        fontSize: '0.2rem',
                        outline: 'none',
                        borderRadius: '3px',
                      }}
                    />
                    {buyButton ? (
                      <Button
                        variant="contained"
                        sx={{
                          ml: '0.1rem',
                          width: '1.1rem',
                          height: '0.45rem',
                          fontSize: '0.25rem',
                        }}
                        onClick={() => onClickMint()}
                      >
                        Mint
                      </Button>
                    ) : (
                      <LoadingButton
                        variant="contained"
                        loading={approveLoading}
                        sx={{
                          ml: '0.1rem',
                          width: '1.1rem',
                          height: '0.45rem',
                          fontSize: '0.15rem',
                        }}
                        onClick={() => onClickApprove()}
                      >
                        Approve
                      </LoadingButton>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                mt="0.6rem"
                justifyContent="flex-start"
                width="100%"
              >
                <Button
                  variant="contained"
                  sx={{
                    width: '1.5rem',
                    height: '0.4rem',
                    fontSize: '0.16rem',
                  }}
                  onClick={() => fetchTokens()}
                >
                  Show NFT
                </Button>
              </Box>
              <Box
                mt="0.1rem"
                border={1}
                borderColor="#979797"
                width="100%"
                height="3.4rem"
                px={1}
                boxSizing="border-box"
                style={{
                  overflowY: 'auto',
                }}
              >
                {tokenLoading ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  Children.toArray(
                    tokenList.map((item) => (
                      <>
                        <TokenItem
                          tokenId={item.tokenId}
                          onClickCross={onClickCross}
                          currentIndex={chainIndex}
                        />
                        <Divider
                          style={{
                            marginTop: '0.1rem',
                          }}
                          light
                        />
                      </>
                    ))
                  )
                )}
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: '0.6rem',
                    color: '#fff',
                  }}
                  gutterBottom
                  component="div"
                >
                  NFT List
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                width: '50px',
              }}
            />
            <Box
              style={{
                flex: 1,
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box height="1.5rem" width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Box display="flex">
                    <Avatar
                      sx={{ width: '0.35rem', height: '0.35rem' }}
                      src={logolist[chainIndex]}
                      alt=""
                    />
                    <span
                      style={{
                        marginLeft: '0.1rem',
                        fontSize: '0.28rem',
                        color: '#FFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {chainlist[chainIndex].chainName}
                    </span>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box
                      sx={{
                        borderRadius: '8px',
                        width: '1.3rem',
                        height: '0.68rem',
                        background: '#332C66',
                        fontSize: '0.3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#FFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {`$ ${currentPrice}`}
                    </Box>
                    <Box
                      mt={0.5}
                      sx={{
                        fontSize: '0.14rem',
                        color: '#FFF',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Current stage price
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        width: '1.3rem',
                        height: '0.68rem',
                        background: '#332C66',
                        fontSize: '0.3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#FFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {`$ ${nextPrice}`}
                    </Box>
                    <Box
                      mt={0.5}
                      sx={{
                        fontSize: '0.14rem',
                        color: '#FFF',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Next stage price
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        width: '1.3rem',
                        height: '0.68rem',
                        background: '#332C66',
                        fontSize: '0.3rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#FFF',
                        fontWeight: 'bold',
                      }}
                    >
                      {totalRemainng}
                    </Box>
                    <Box
                      mt={0.5}
                      sx={{
                        fontSize: '0.14rem',
                        color: '#FFF',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Total Remaining
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  width: '100%',
                  height: '3rem',
                  fontSize: '0.2rem',
                  WebkitBoxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  boxSizing: 'border-box',
                }}
              >
                <img
                  src={steplist[priceIndex]}
                  alt=""
                  style={{
                    width: '7.75rem',
                    // height: '3.5rem',
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                style={{
                  marginTop: '1.05rem',
                  color: '#fff',
                }}
                gutterBottom
                component="div"
              >
                Stage
              </Typography>
            </Box>
          </div>
        </Box>
      </div>
      <div className="bottom-background">
        <img alt="" src={bottomImage} className="bottom-image" />
        <div className="footer">
          <div className="footer-wrapper">
            <Stack direction="row" spacing={10}>
              <Box>
                <Button
                  sx={{
                    width: '1.6rem',
                    height: '0.7rem',
                    fontSize: '0.25rem',
                    textTransform: 'none',
                  }}
                  variant="outlined"
                  color="white"
                  onClick={() => onClickJump('https://twitter.com/labfordup')}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{
                        fontSize: 28,
                      }}
                    />
                  }
                >
                  dLab
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    width: '1.6rem',
                    height: '0.7rem',
                    fontSize: '0.25rem',
                    textTransform: 'none',
                  }}
                  variant="outlined"
                  color="white"
                  onClick={() =>
                    onClickJump('https://twitter.com/OmnichainSwap')
                  }
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{
                        fontSize: 28,
                      }}
                    />
                  }
                >
                  Oswap
                </Button>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
