import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Home from './pages/Home';

function App() {
  const theme = createTheme({
    typography: {
      htmlFontSize: 100,
    },
    palette: {
      primary: {
        main: '#17B449',
        // main: '#1BA27A',
        contrastText: '#fff',
      },
      grey: {
        main: '#999999',
        contrastText: '#fff',
      },
      white: {
        main: '#FFF',
        contrastText: '#fff',
      },
    },
  });

  const resizeListener = () => {
    const designSize = 1920;
    const html = document.documentElement;
    const clientW = html.clientWidth;
    const htmlRem = (clientW * 100) / designSize;
    html.style.fontSize = `${htmlRem}px`;
    theme.typography.htmlFontSize = htmlRem;
  };
  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
